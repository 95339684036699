//Specific JS for this skin
require(['jquery'], function ($) {
    $('#mini-cart .hide-below-960').html("<i class='fa fa-shopping-cart'></i>");
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");
    /*Remove certain classes to expand the footer*/
    $('#footer .footer-top-container .footer-top').removeClass('footer container');
    $('#footer .footer-primary-container .footer-primary').removeClass('footer container');
    // To ensure the footer stays at the bottom
    $(window).on("load", function() {
      const newh = $(window).outerHeight() - $('.header-container').outerHeight() - $('.main-container').outerHeight() - $('.footer-container').outerHeight();
      if (newh > 0) {
        $('.main-container').css('min-height', $('.main-container').outerHeight() + newh +'px');
      }
	});
    //Change the layout for category pages to show 3 products in a row for devices > 767px and < 1200px;
      if($(window).width() > 767 && $(window).width() < 1200) {
        $('ul.category-products-listing.products-grid').removeClass('itemgrid-4col');
        $('ul.category-products-listing.products-grid').addClass('itemgrid-3col');
      }

});
